// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ebook-formats-js": () => import("./../../../src/pages/about-ebook-formats.js" /* webpackChunkName: "component---src-pages-about-ebook-formats-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accept-invite-js": () => import("./../../../src/pages/accept-invite.js" /* webpackChunkName: "component---src-pages-accept-invite-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-center-js": () => import("./../../../src/pages/knowledge-center.js" /* webpackChunkName: "component---src-pages-knowledge-center-js" */),
  "component---src-pages-latest-announcements-js": () => import("./../../../src/pages/latest-announcements.js" /* webpackChunkName: "component---src-pages-latest-announcements-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-with-coupon-code-js": () => import("./../../../src/pages/register-with-coupon-code.js" /* webpackChunkName: "component---src-pages-register-with-coupon-code-js" */),
  "component---src-pages-request-password-reset-js": () => import("./../../../src/pages/request-password-reset.js" /* webpackChunkName: "component---src-pages-request-password-reset-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

