import React, { createContext, useContext, useReducer } from 'react'
import { fetchUrl } from '../apiClient/baseApi'


const DEFAULT_STATE = {
  token: typeof window !== 'undefined' ? window.localStorage.getItem('access_token') || null : null,
  user: typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('user')) || {} : {},
  loggedIn: typeof window !== 'undefined' ? window.localStorage.getItem('access_token') ? true : false : false
} 

const reducer = (state, action) => {
  switch(action.type){
    case 'LOGIN': 
        const { access_token } = action.payload
        window.localStorage.setItem('access_token', access_token)
        return { ...state, token: access_token, user: {}, loggedIn: true } 
    case 'LOGOUT': 
      window.localStorage.clear();
      return { ...state, token: null, user: {}, loggedIn: false }
    default:
      return DEFAULT_STATE
  }
}

const AuthContext = createContext()

const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useReducer(reducer, DEFAULT_STATE)}>
    { children }
  </AuthContext.Provider>
)

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    { element }
  </AuthProvider>
)

const useAuth = () => {
  const [state, dispatcher] = useContext(AuthContext)
  const isAuthenticated = state.loggedIn

  const login = (credentials) => new Promise(async (resolve, reject) => {
    try{
      const { data: payload } = await fetchUrl('post',  '/auth/login', credentials)
      dispatcher({ type: 'LOGIN', payload })
      resolve(payload)
    }catch(e){
      reject(e)
    }
  })

  const social_login = (refToken) => new Promise(async (resolve, reject) => {
    let refData = { "refresh_token": refToken }
    try{
      const { data: payload } = await fetchUrl('post',  '/auth/refresh/', refData)
      dispatcher({ type: 'LOGIN', payload })
      resolve(payload)
    }catch(e){
      reject(e)
    }
  })
  
  const logout = () => {
    dispatcher({ type: 'LOGOUT' })
  }

  return { state, isAuthenticated, login, logout, social_login } 
}

export default useAuth