import axios from 'axios'
const toastr = require('toastr')

export const baseurl = process.env.GATSBY_API_URL
//export const baseurl = '/api'

const GET = 'GET'
const SOCIAL = 'SOCIAL'
const DELETE = 'DELETE'
const POST = 'POST'
const PUT = 'PUT'
const PATCH = 'PATCH'
const ASSETS = 'ASSETS'

 toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }; 

const ACTION_HANDLERS = {
  [GET] : (url, data) => axios.get(baseurl + url, data),
  [SOCIAL] : (url, data) => axios.get(baseurl + url, data),
  [ASSETS] : (url) => axios.get(baseurl + '/assets/' + url, {responseType: 'blob'} ),
  [DELETE] : (url, data) => axios.delete(baseurl + url, { data: data }),
  [POST] : (url, data) => axios.post(baseurl + url, data),
  [PUT] : (url, data) => axios.put(baseurl + url, data),
  [PATCH] : (url, data) => axios.patch(baseurl + url, data)
}

export const setHeaders = (contentType) => {
  // set auth token
  let token = localStorage.getItem('access_token')
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }

  // set contentType
  if (contentType) {
    axios.defaults.headers.post['Content-Type'] = contentType
  } else {
    delete axios.defaults.headers.post['Content-Type']
  }

}

export const showErrorAsToast = (error) => {
    //console.log(error.response)
   error.response.data.errors.forEach((err) => {
      // console.log(err.message)
    toastr.options.closeButton = false
    toastr.error(err.message)
  })
  return Promise.reject(error.response.data.message) 
}

export const fetchUrl = (type, url, data, fetchBaseResponse = false, contentType, handleError = true) => {
  setHeaders(contentType)
  if (type === 'social') {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  }
  const handler = ACTION_HANDLERS[type.toUpperCase()]
  return (!fetchBaseResponse && handleError
  ? handler(url, data)
    .then(res => Promise.resolve(res.data))
    .catch(error => showErrorAsToast(error))
  : handleError
  ? handler(url, data)
    .catch(error => showErrorAsToast(error))
        : handler(url, data)
  )
}

/* export const download = (url, type) => {
  let token = localStorage.getItem('access_token')
  window.open(baseurl + url + '?tk=' + token + `${type ? '&type=' + type : ''}`)
} */

export const getUserId = () => {
  let user = localStorage.getItem('user')
  if (user) {
    return JSON.parse(user).id
  }
}
